import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import './ChallengeDetails.css';
import { acceptChallenge, cancelChallenge, getChallengeViewById, rejectChallenge } from '../../services/ChallengeService';
import { ChallengeView } from '../../types/ChallengeView';
import { useAuth } from '../../context/AuthContext';
import AcceptChallengeButton from '../Buttons/AcceptChallengeButton/AcceptChallengeButton';
import { translateRankingType } from '../../helpers/translateRankingType';
import { translateChallengeStatus } from '../../helpers/translateChallengeStatus';
import RejectChallengeButton from '../Buttons/RejectChallengeButton/RejectChallengeButton';
import CancelChallengeButton from '../Buttons/CancelChallengeButton/CancelChallengeButton';
import { formatDate, isDateBeforeOrEqualtoToday, isDateBeforeToday } from '../../helpers/timeHelpers';
import LoadResultButton from '../Buttons/LoadResultButton/LoadResultButton';

const ChallengeDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [challenge, setChallenge] = useState<ChallengeView | null>(null);
  const [challengeDisplayDate, setChallengeDisplayDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { playerAuth } = useAuth();

  useEffect(() => {
    if (id) {
      const fetchChallenge = async () => {
        try {
          const data = await getChallengeViewById(parseInt(id, 10));
          const adjustedPlus1HourDate = new Date(data.proposedDate);
          setChallengeDisplayDate(formatDate(adjustedPlus1HourDate));
          setChallenge(data);
        } catch (err) {
          setError('Error al obtener los detalles del desafío.');
        } finally {
          setLoading(false);
        }
      };

      fetchChallenge();
    }
  }, [id]);

  const handleAcceptChallenge = async (challengeId: number) => {
    try {
      await acceptChallenge(challengeId);

      window.location.reload();
    } catch (err) {
      console.error('Error al aceptar el desafío');
    }
  };

  const handleRejectChallenge = async (challengeId: number) => {
    try {
      await rejectChallenge(challengeId);

      window.location.reload();
    } catch (err) {
      console.error('Error al aceptar el desafío');
    }
  };

  const handleCancelChallenge = async (challengeId: number) => {
    try {
      await cancelChallenge(challengeId);

      window.location.reload();
    } catch (err) {
      console.error('Error al aceptar el desafío');
    }
  };

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;
  if (!challenge) return <p>Desafío no encontrado.</p>;

  return (
    <div className="challenge-details-container">
      {/* Page Title and meta info */}
      {/* Meta información y título dinámico */}
      <Helmet>
        <title>
          {challenge.challenger.firstName} {challenge.challenger.lastName} vs {challenge.challenged.firstName} {challenge.challenged.lastName} - {challengeDisplayDate}
        </title>
        <meta
          name="description"
          content={`Detalles del desafío: ${translateChallengeStatus(challenge.status)} en la categoría ${translateRankingType(challenge.rankingType)}. Fecha propuesta: ${challengeDisplayDate}.`}
        />
        <meta property="og:title" content={`${challenge.challenger.firstName} ${challenge.challenger.lastName} vs ${challenge.challenged.firstName} ${challenge.challenged.lastName} - ${challengeDisplayDate}`} />
        <meta
          property="og:description"
          content={`Estado: ${translateChallengeStatus(challenge.status)}, categoría: ${translateRankingType(challenge.rankingType)}, fecha: ${challengeDisplayDate}.`}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      {challenge.isTestChallenge && (
        <div className="test-challenge-banner">
          <p>Prueba de Nivel</p>
        </div>
      )}
      {challenge.isFreeChallenge && (
        <div className="free-challenge-banner">
          <p>Desafío Libre</p>
        </div>
      )}
      <div>
        <p>Desafío <strong>{translateChallengeStatus(challenge.status)}</strong></p>
        <div className="challenge-details">
          <div className="player-box-info">
            <div className="player-name-box-info">
              <p>DESAFIANTE</p>
              <Link to={`/players/${challenge.challenger.id}`}>
                <strong>{challenge.challenger.firstName} {challenge.challenger.lastName}</strong>
              </Link>
            </div>
            <p>Nivel: <strong>{challenge.challenger.dynamicLevel.toFixed(2)}</strong></p>
            <p>Confiabilidad: <strong>{challenge.challenger.rankingReliability !== undefined ? `${(challenge.challenger.rankingReliability * 100).toFixed(0)}%` : 'N/A'}</strong></p>
            <p>Ranking: <strong>{challenge.challenger.playerPreMatchRanking}</strong></p>
          </div>
          <div className="player-box-info">
            <div className="player-name-box-info">
              <p>DESAFIADO</p>
              <Link to={`/players/${challenge.challenged.id}`}>
                <strong>{challenge.challenged.firstName} {challenge.challenged.lastName}</strong>
              </Link>
            </div>
            <p>Nivel: <strong>{challenge.challenged.dynamicLevel.toFixed(2)}</strong></p>
            <p>Confiabilidad: <strong>{challenge.challenged.rankingReliability !== undefined ? `${(challenge.challenged.rankingReliability * 100).toFixed(0)}%` : 'N/A'}</strong></p>
            <p>Ranking: <strong>{challenge.challenged.playerPreMatchRanking}</strong></p>
          </div>
        </div>
        <div className='challenge-info'>
          <div className='challenge-info-container'>
            <img src="/icons/podium.png" alt="Whats Icon" />
            <p><strong>{translateRankingType(challenge.rankingType)}</strong></p>
          </div>
          <div className='challenge-info-container'>
            <img src="/icons/calendar.png" alt="Whats Icon" />
            <p><strong>{formatDate(challenge.proposedDate)}</strong></p>
          </div>
          {/* Show serve info */}
          {challenge.status && (challenge.status === "accepted") && (
            <>
              <div className='challenge-serve-info-container'>
                <img src="/icons/tennis-balls.png" alt="Whats Icon" />
                <p><strong>{challenge.isPlayer1BringingBalls ? challenge.challenger.firstName + " " + challenge.challenger.lastName : challenge.challenged.firstName + " " + challenge.challenged.lastName} debe poner bolas</strong></p>
              </div>
            </>
          )}
        </div>

        {/* Show results if available */}
        {challenge.result && (
          <div className="challenge-result">
            {challenge.result.walkover ? (
              <p><strong>Resultado</strong> W/O</p>
            ) : challenge.result.abandoned ? (
              <p><strong>Resultado</strong> {challenge.result.sets.map(set => set.score).join(', ')} y abandono</p>
            ) : (
              <>
                <p><strong>Resultado</strong> {challenge.result.sets.map(set => set.score).join(', ')}</p>
              </>
            )}
            <p className='result-player-link-container'>
              <strong>Ganador</strong> {challenge.winnerId === challenge.challenger.id ? (
              <Link
                className='result-player-link'
                to={`/players/${challenge.challenger.id}`}
                onClick={(event) => event.stopPropagation()}
              >
                <span>{challenge.challenger.firstName} {challenge.challenger.lastName}</span>
                {/* Icon to show if moved up */}
                {challenge.challengerMovedUp > 0 && (
                  <>
                    <span>
                      <img className="moved-up-icon" src="/icons/moved-up.png" alt="MovedUp" />
                      <strong className='ranks-moved-up-strong'>{challenge.challengerMovedUp}</strong>
                    </span>
                  </>
                )}
              </Link>
            ) : (
              <Link
                className='result-player-link'
                to={`/players/${challenge.challenged.id}`}
                onClick={(event) => event.stopPropagation()}
              >
                <span>{challenge.challenged.firstName} {challenge.challenged.lastName}</span>
                {/* Icon to show if moved up */}
                {challenge.challengedMovedUp > 0 && (
                  <>
                    <span>
                      <img className="moved-up-icon" src="/icons/moved-up.png" alt="MovedUp" />
                      <strong className='ranks-moved-up-strong'>{challenge.challengedMovedUp}</strong>
                    </span>
                  </>
                )}
              </Link>
            )}</p>
          </div>
        )}

        {/* Show if the match is confirmed */}
        {challenge.status === 'played' && (
          <div className="match-confirmation">
            {challenge.isMatchConfirmed ? <img src="/icons/accept.png" alt="Confirmado" /> : <img src="/icons/time.png" alt="ConfirmacionPendiente" />}
            <p>Resultado {challenge.isMatchConfirmed ? 'confirmado' : 'esperando confirmacion'}</p>
          </div>
        )}

        <div className="challenge-buttons">
          {/* Show accept button if the authenticated player is the challenged player */}
          {playerAuth && playerAuth.id === challenge.challenged.id && challenge.status === 'pending' && (
            <>
              <AcceptChallengeButton challengeId={challenge.id} onAccept={handleAcceptChallenge} />
              <RejectChallengeButton challengeId={challenge.id} onReject={handleRejectChallenge} />
            </>
          )}
        </div>

        {/* Show cancel button if the authenticated player is the challenged/er player and the status is Accepted */}
        {playerAuth && (playerAuth.id === challenge.challenged.id || playerAuth.id === challenge.challenger.id) && challenge.status === 'accepted' &&
          !isDateBeforeToday(new Date(challenge.proposedDate)) && (
            <><CancelChallengeButton challengeId={challenge.id} onCancel={handleCancelChallenge} /></>
          )}

        {/* Show Load result button */}
        {playerAuth &&
          (playerAuth.id === challenge.challenged.id || playerAuth.id === challenge.challenger.id) &&
          challenge.status === 'accepted' &&
          isDateBeforeOrEqualtoToday(new Date(challenge.proposedDate)) && (
            <><LoadResultButton challengeId={challenge.id} isAuthenticated /></>
          )}

        {/* Show cancel button if the authenticated player is the challenger player and the status is Pending */}
        {playerAuth && (playerAuth.id === challenge.challenger.id) && challenge.status === 'pending' &&
          !isDateBeforeToday(new Date(challenge.proposedDate)) && (
            <CancelChallengeButton challengeId={challenge.id} onCancel={handleCancelChallenge} />
          )}

      </div>
    </div>
  );
};

export default ChallengeDetails;
